import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormInput = _resolveComponent("FormInput")!
  const _component_CalendarInput = _resolveComponent("CalendarInput")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_FormInput, {
      class: "mb-7",
      label: _ctx.$t('form.name'),
      type: "text",
      model: _ctx.form.name,
      "onUpdate:model": _cache[0] || (_cache[0] = ($event: any) => (_ctx.form.name = $event)),
      hasError: _ctx.v$.form.name.$error,
      error: _ctx.v$.form.name.$errors[0]
    }, null, 8, ["label", "model", "hasError", "error"]),
    _createVNode(_component_FormInput, {
      class: "mb-7",
      label: _ctx.$t('form.surname'),
      type: "text",
      model: _ctx.form.surname,
      "onUpdate:model": _cache[1] || (_cache[1] = ($event: any) => (_ctx.form.surname = $event)),
      hasError: _ctx.v$.form.surname.$error,
      error: _ctx.v$.form.surname.$errors[0]
    }, null, 8, ["label", "model", "hasError", "error"]),
    _createVNode(_component_CalendarInput, {
      onUpdateModel: _cache[2] || (_cache[2] = ($event: any) => (_ctx.updateModel($event, 'birthdate'))),
      class: "mb-7 w-1/2",
      label: _ctx.$t('form.birthdate'),
      model: _ctx.form.birthdate,
      "onUpdate:model": _cache[3] || (_cache[3] = ($event: any) => (_ctx.form.birthdate = $event)),
      hasError: _ctx.v$.form.birthdate.$error,
      error: _ctx.v$.form.birthdate.$errors[0]
    }, null, 8, ["label", "model", "hasError", "error"]),
    _createVNode(_component_FormInput, {
      class: "mb-7 w-1/2",
      label: _ctx.$t('form.birthnumber'),
      type: "text",
      model: _ctx.form.birthnumber,
      "onUpdate:model": _cache[4] || (_cache[4] = ($event: any) => (_ctx.form.birthnumber = $event)),
      hasError: _ctx.v$.form.birthnumber.$error,
      error: _ctx.v$.form.birthnumber.$errors[0]
    }, null, 8, ["label", "model", "hasError", "error"]),
    _createElementVNode("button", {
      onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args))),
      class: "mt-5 mb-12 w-full btn secondary flex-1"
    }, _toDisplayString(_ctx.$t("continue")), 1)
  ], 64))
}