
import { Options, Vue } from "vue-class-component";
import SectionTitle from "@/shared/components/SectionTitle.vue";
import LeftStepBar from "@/shared/components/LeftStepBar.vue";
import ChildPersonalInfoForm from "@/components/forms/ChildPersonalInfoForm.vue";

@Options({
  components: {
    SectionTitle,
    ChildPersonalInfoForm,
    LeftStepBar,
  },
  beforeMount() {
    this.$store.commit("onboarding/checkProfile");
  },
})
export default class ChildPersonalInfo extends Vue {}
