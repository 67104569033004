
import useVuelidate from "@vuelidate/core";
import { required } from "@/utils/i18n-validators";
import { Options, Vue } from "vue-class-component";
import FormInput from "@/shared/components/FormInput.vue";
import FormCheckbox from "@/shared/components/FormCheckbox.vue";
import CalendarInput from "@/shared/components/CalendarInput.vue";
import { helpers } from "@vuelidate/validators";
import { maxDate } from "@/utils/validators";

@Options({
  data() {
    return {
      v$: useVuelidate(),
      formSubmitted: false,
      form: {
        name: "",
        surname: "",
        birthdate: "",
        birthnumber: "",
      },
    };
  },
  components: {
    FormInput,
    FormCheckbox,
    CalendarInput,
  },
  validations() {
    return {
      form: {
        name: { required },
        surname: { required },
        birthdate: {
          required,
          maxDate: helpers.withMessage(
            this.$t("validations.birthdate"),
            maxDate
          ),
        },
        birthnumber: { required },
      },
    };
  },
  methods: {
    updateModel(value: string, model: string) {
      this.v$.form[model].$touch();
      this.form[model] = value;
    },
    submitForm() {
      this.v$.$validate();
      this.formSubmitted = true;
      const form = this.form;
      var params = new URLSearchParams();
      params.append("name", form.name);
      params.append("surname", form.surname);
      params.append("birthdate", form.birthdate);
      params.append("birthnumber", form.birthnumber);
      if (!this.v$.$invalid) {
        this.$store.dispatch("onboarding/sendChildAndOwnerInfo", params);
      }
    },
  },
})
export default class ChildPesonalInfo extends Vue {}
